import type {WebsiteVisibilityChangeEvent_1_0} from '@shopify/monorail/lib/schemas';

import type {Track, Store} from '../types';
import {Events} from '../types';
import {raceListeners} from '../utils/listeners';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

const HIDDEN = 'hidden';
const VISIBILE = 'visible';

export const initVisibilityTracking = (track: Track, store: Store) => {
  let startTime = new Date().getTime();

  type NormalizedVisibilityEvent = 'hidden' | 'visible';

  const sendVisibilityEvent =
    (currentState: NormalizedVisibilityEvent) => () => {
      const endTime = new Date().getTime();
      const event: MonorailEventSchema<WebsiteVisibilityChangeEvent_1_0> = {
        schemaId: MonorailEventSchemaId.VisibilityChange,
        payload: {
          pageViewToken: store.pageViewToken || '',
          state: currentState,
          duration: Math.round(endTime - startTime),
        },
      };

      track.dux(event, {flush: true});

      startTime = endTime;

      attachNextVisibilityEvent(currentState === VISIBILE ? HIDDEN : VISIBILE);
    };

  // First listener to get a visibility-change event will remove existing listeners
  // and will add listeners for next state
  const attachNextVisibilityEvent = (nextState: NormalizedVisibilityEvent) => {
    raceListeners([
      [Events.VisibilityChange, sendVisibilityEvent(nextState), document],
      [
        nextState === VISIBILE ? Events.PageShow : Events.PageHide,
        sendVisibilityEvent(nextState),
        window,
        true,
      ],
    ]);
  };

  attachNextVisibilityEvent(HIDDEN);
};
