import type {Store, Track} from './client/types';
import type {MonorailEventSchema, MonorailEventSchemaId} from './schema-types';

export * from './enums';
export type * from './schema-types';
export interface ConfigMetadata {
  site?: Site;
  page: DataLayer;
  pageId?: string;
  handle?: string;
  regions: Region[];
  title?: string;
  rootDomain?: string;
  extraData?: {
    currencyCode?: string;
  };
}

export type Mode = 'development' | 'production' | 'staging' | 'storybook';

export interface DataLayer {
  affiliate?: string;
  blogCategory?: string;
  blogId?: string;
  continentCode?: string;
  countryCurrencyCode?: string;
  environment?: string;
  experimentVariationId?: string;
  httpCode?: string;
  language?: string;
  pageCategory?: string;
  pageGroup?: string;
  pageSubtopic?: string;
  pageTopic?: string;
}

export interface Region {
  domain: string;
  locale?: string;
  pathPrefix?: string;
  label: string;
  hreflang: string;
  lang: string;
  selector: boolean;
  // set by loader data
  href?: string;
  base?: boolean;
  isActive?: boolean;
}

/**
 * Site configuration
 * @property {string} countryCode - This is NOT the User countryCode. ISO 3166-1 alpha-2 country code of the domain/site.
 */
export interface Site {
  countryCode?: string;
  countryCurrencyCode?: string;
  currencyCode?: string;
  domain?: string;
  features?: string[];
  googleVerificationCodes?: string[];
  hardwareStoreUrl?: string;
  hreflang?: string;
  languageCode?: string;
  locale?: string;
  pathPrefix?: string;
  baseEnDomain?: boolean;
}

export interface DuxPageEvent {
  url: string;
  userLanguage: string;
  httpStatusCode: string;
  experimentVariationId?: string;
  referrer?: string;
  pageViewToken: string;
  multiTrackToken?: string;
  sessionToken?: string;
  sessionId?: string;
  identityUuid?: string;
  userId?: number;
  shopifyEmployee?: boolean;
  shopifyEmployeeId?: string;
  siteEnvironment: Mode;
}

export type DuxEvent<T> = DuxPageEvent | MonorailEventSchema<T>;

export interface EventMessage<T> {
  multiTrackToken?: string;
  sessionToken?: string;
  pageViewToken?: string;
  siteEnvironment?: Mode;
  url?: string;
  events: DuxEvent<T>[];
}

export const isMonorailEvent = <T>(
  event: DuxEvent<T>,
): event is MonorailEventSchema<T> => {
  return (event as MonorailEventSchema<T>).schemaId !== undefined;
};

export const isDuxEvent = <T>(event: DuxEvent<T>): event is DuxPageEvent => {
  return (event as DuxPageEvent).url !== undefined;
};

export type ViewabilityHandler = (props: ViewabilityHandlerProps) => void;

export interface ViewabilityHandlerProps {
  store: Store;
  track: Track;
  dom: HTMLElement | null;
  isIntersecting: boolean;
  clientMessageId: string;
  parentSchemaId: MonorailEventSchemaId.ComponentViewability;
}

export interface DidomiStore {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: string;
  created: string;
  updated: string;
  version: number | null;
  purposes?: {
    enabled: string[];
    disabled: string[];
  };
  vendors?: {
    enabled: string[];
    disabled: string[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  vendors_li?: {
    enabled: string[];
  };
}
