export default function debounce<T extends (...args: any[]) => any>(
  callback: T,
  timeout?: number,
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let queuedTimer: ReturnType<typeof setTimeout>;
  let queuedRaf: ReturnType<typeof requestAnimationFrame>;

  return (...args: Parameters<T>) => {
    if (queuedTimer) {
      clearTimeout(queuedTimer);
    }
    if (queuedRaf) {
      cancelAnimationFrame(queuedRaf);
    }
    return new Promise<ReturnType<T>>((resolve) => {
      if (timeout || typeof requestAnimationFrame === 'undefined') {
        queuedTimer = setTimeout(() => {
          const returnValue = callback(...args) as ReturnType<T>;
          resolve(returnValue);
        }, timeout);
      } else {
        queuedRaf = requestAnimationFrame(() => {
          const returnValue = callback(...args) as ReturnType<T>;
          resolve(returnValue);
        });
      }
    });
  };
}
