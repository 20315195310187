import {DOMAIN_LOCALE_MAPPINGS} from '../client/constants';

export const localeFromUrl = (url: URL): string | null => {
  const domain = url.host;

  const domainToLocale =
    DOMAIN_LOCALE_MAPPINGS[domain] || DOMAIN_LOCALE_MAPPINGS.default;

  if (typeof domainToLocale === 'string') {
    return domainToLocale;
  }

  const [, pathToLocale = ''] = url.pathname.toLowerCase().split('/');

  return domainToLocale[pathToLocale] || domainToLocale.default || 'en';
};
