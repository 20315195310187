import type {WebsiteClickEvent_1_5} from '@shopify/monorail/lib/schemas';
import {v4} from 'uuid';

import {
  CLICK_EVENT_SELECTORS,
  CLICK_EVENT_SELECTORS_DISABLED,
  GTM_DATA_SELECTORS,
} from '../constants';
import type {Track, Store, GtmClickEventData} from '../types';
import {getComponentTreeInfo, isFixedPosition} from '../utils/componentTree';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';
import {addListener} from '../utils/listeners';
import {camelCaseToSnakeCaseAllProps} from '../../utils/camelCaseToSnakeCase';

/**
 * Relative position of element not position of mouse cursor at time of click
 * Theoretically you could now evaluate moving buttons pixel position to evaluate impact to click through
 * @param el
 * @returns
 */
export const getElementPosition = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const isRelative = !isFixedPosition(el);
  return {
    left: Math.round(
      rect.left + (isRelative ? window.scrollX - el.clientLeft : 0),
    ),
    top: Math.round(
      rect.top + (isRelative ? window.scrollY - el.clientTop : 0),
    ),
  };
};

export const handleClick = ({
  element,
  clientMessageId = v4(),
  eventType,
  track,
  store,
  options = {
    extraMetadata: {},
  },
}: {
  element: HTMLElement;
  clientMessageId?: string;
  eventType: string;
  track: Track;
  store: Store;
  options?: {
    extraMetadata: {[key: string]: any};
  };
}) => {
  const clickableEle: HTMLElement | null = element.closest(
    CLICK_EVENT_SELECTORS,
  );
  if (!clickableEle) return;
  if (clickableEle.matches(CLICK_EVENT_SELECTORS_DISABLED)) return;

  const {left: xCoord, top: yCoord} = getElementPosition(clickableEle);
  const {
    elementName,
    elementType,
    sectionName,
    sectionIndex,
    componentTree,
    extraMetadata,
  } = getComponentTreeInfo(clickableEle);
  const combinedExtraMetadata = {
    ...extraMetadata,
    ...options?.extraMetadata,
  };
  const href = clickableEle.getAttribute('href') || '';
  let innerText =
    clickableEle.innerText ||
    clickableEle.textContent ||
    clickableEle.getAttribute('aria-label') ||
    '';

  // slices using codePoints instead of character length to avoid separating surrgate pairs (emojis)
  innerText = [...innerText].slice(0, 50).join('');
  const event: MonorailEventSchema<WebsiteClickEvent_1_5> = {
    schemaId: MonorailEventSchemaId.Click,
    payload: {
      pageViewToken: store.pageViewToken || '',
      componentTree,
      xCoord,
      yCoord,
      recirculation: href,
      targetName: elementName,
      parentName: sectionName,
      parentIndex: sectionIndex,
      clickType: elementType,
      eventType,
      innerText,
      extraMetadata: JSON.stringify(combinedExtraMetadata),
    },
  };
  track.dux(event, {
    clientMessageId,
  });

  if (track.gtm) {
    const elWithData = element.closest(GTM_DATA_SELECTORS);
    let data: GtmClickEventData;

    if (elWithData) {
      data = (elWithData as HTMLElement).dataset;
    } else {
      data = {
        eventCategory: sectionIndex
          ? `${sectionName}-${sectionIndex}`
          : sectionName,
        eventAction: elementType,
        eventLabel: elementName,
      };
    }

    // GA4 data mapping
    Object.assign(data, {
      // duplicated event and eventName to support plus migration
      event: elementType,
      eventName: elementType,
      eventType: elementName,
      eventText: innerText,
      eventLocation: sectionIndex
        ? `${sectionName}-${sectionIndex}`
        : sectionName,
      eventUrl: href,
      extraMetadata: JSON.stringify(
        camelCaseToSnakeCaseAllProps(combinedExtraMetadata),
      ),
    });

    // If tealium or gtm shims are available then send the data to them
    track.gtm(data);
  }
};

let cachedEventListener: (evt: MouseEvent) => void;

const addClickEventListeners = (elementSelectors: (HTMLElement | null)[]) => {
  if (cachedEventListener) {
    elementSelectors?.forEach((element) => {
      addListener('click', cachedEventListener, element, true);
      addListener('contextmenu', cachedEventListener, element, true);
      addListener('auxclick', cachedEventListener, element, true);
    });
  }
};

// Unused now that trying to prevent double track between js and react-synthetic events
// now that dom elements are being passed in from various init functions
// this would need to be rethought to capture all those selectors if re-implemented
// const removeClickEventListeners = (elementSelectors: (HTMLElement | null)[]) => {
//   if (cachedEventListener) {
//     elementSelectors?.forEach((element) => {
//       element?.removeEventListener('click', cachedEventListener);
//       element?.removeEventListener('contextmenu', cachedEventListener);
//       element?.removeEventListener('auxclick', cachedEventListener);
//     });
//   }
// };

export const initClickTracking = (
  track: Track,
  store: Store,
  elementSelectors: (HTMLElement | null)[],
) => {
  cachedEventListener = (evt: MouseEvent) => {
    handleClick({
      element: evt.target as HTMLElement,
      eventType: evt.type,
      track,
      store,
    });
  };

  addClickEventListeners(elementSelectors);
};
