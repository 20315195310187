import {ApprovedCookie, DidomiDefault, DidomiEvent} from '../../enums';
import type {Track, Store, DidomiConfig, UpdateStore} from '../types';
import {getCookie} from '../utils/cookie';

/**
 * Controls Didomi ui user and app config
 * https://developers.didomi.io/cmp/web-sdk/consent-notice/notice/behavior#configuration-by-user-country
 * @param url current url to parse for debug params
 * @returns config object to override default user and app config
 */
const getPrivacyRegionOverride = (url: string) => {
  const searchParams = new URL(url).searchParams;
  const didomiConfigOverride: any = {};
  const gdprOverride = searchParams.get(DidomiDefault.DebugGdpr);
  const ccpaOverride = Boolean(searchParams.get(DidomiDefault.DebugCcpa));
  const langOverride = searchParams.get(DidomiDefault.DebugLang);
  if (ccpaOverride) {
    didomiConfigOverride.user = {
      country: 'US',
      region: 'CA',
    };
  } else if (gdprOverride) {
    didomiConfigOverride.user = {
      country: gdprOverride.toUpperCase(),
    };
  }
  if (langOverride) {
    // force notice to specific language
    didomiConfigOverride.languages = {
      enabled: [langOverride.toLowerCase()],
    };
  }
  return didomiConfigOverride;
};

export const initDidomi = ({
  track,
  store,
  accountId = DidomiDefault.AccountId,
  noticeId = DidomiDefault.NoticeId,
  didomiConfig = {},
  onReady,
  updateStore,
}: DidomiConfig & {
  track: Track;
  store: Store;
  updateStore: UpdateStore;
}) => {
  const updateDidomiToken = () => {
    const didomiToken =
      getCookie(ApprovedCookie.DidomiToken) ||
      getCookie(ApprovedCookie.DidomiTokenCpra);

    const {didomiToken: persistedDidomiToken} = store;
    let didomiStore;
    if (didomiToken) {
      try {
        didomiStore = JSON.parse(atob(didomiToken));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    if (didomiToken !== persistedDidomiToken) {
      updateStore({
        didomiToken,
        didomiStore,
      });
    }
  };

  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function (di: any) {
    updateDidomiToken();
    if (onReady) onReady(di);
  });
  window.didomiConfig = {
    cookies: {
      storageSources: {
        cookies: true,
        localStorage: false,
      },
    },
    ...didomiConfig,
    ...getPrivacyRegionOverride(window.location.href),
  };

  window.didomiEventListeners = window.didomiEventListeners || [];
  window.didomiEventListeners.push({
    event: DidomiEvent.ConsentChanged,
    listener() {
      updateDidomiToken();
    },
  });

  /* eslint-disable eslint-comments/no-unlimited-disable */
  /* eslint-disable */
  // @ts-expect-error minified code copied from console.didomi.io
  (function(){(function(e,r){var t=document.createElement("link");t.rel="preconnect";t.as="script";var n=document.createElement("link");n.rel="dns-prefetch";n.as="script";var i=document.createElement("link");i.rel="preload";i.as="script";var o=document.createElement("script");o.id="spcloader";o.type="text/javascript";o["async"]=true;o.charset="utf-8";var a="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+r;if(window.didomiConfig&&window.didomiConfig.user){var c=window.didomiConfig.user;var s=c.country;var d=c.region;if(s){a=a+"&country="+s;if(d){a=a+"&region="+d}}}t.href="https://sdk.privacy-center.org/";n.href="https://sdk.privacy-center.org/";i.href=a;o.src=a;var p=document.getElementsByTagName("script")[0];p.parentNode.insertBefore(t,p);p.parentNode.insertBefore(n,p);p.parentNode.insertBefore(i,p);p.parentNode.insertBefore(o,p)})(accountId, noticeId)})();
  /* eslint-enable */
};
