export const camelCaseToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLocaleLowerCase()}`);
};

export const camelCaseToSnakeCaseAllProps = (
  obj: any,
  preserveCase: string[] = [],
) => {
  const result: {[key: string]: any} = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (preserveCase.indexOf(key) >= 0) {
        result[key] = value;
      } else {
        const snakeCaseKey = camelCaseToSnakeCase(key);
        result[snakeCaseKey] = value;
      }
    }
  }

  return result;
};
