import type {WebsiteErrorEvent_1_0} from '@shopify/monorail/lib/schemas';

import {onceListener} from '../utils/listeners';
import type {Track, Store} from '../types';
import {Events} from '../types';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

export const initErrorTracking = (track: Track, store: Store) => {
  onceListener(Events.Error, (errorEvent: ErrorEvent) => {
    const event: MonorailEventSchema<WebsiteErrorEvent_1_0> = {
      schemaId: MonorailEventSchemaId.Error,
      payload: {
        pageViewToken: store.pageViewToken || '',
        message: errorEvent.message,
      },
    };
    track.dux(event);
  });
};
