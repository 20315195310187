import debounce from '../utils/debounce';
import type {ListenerId} from '../utils/listeners';
import {addListener, onceListener, removeListener} from '../utils/listeners';
import type {Track, Store} from '../types';
import {Events} from '../types';
import type {
  MonorailEventSchema,
  WebsiteScrollEvent_1,
} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

let listenerAr: ListenerId[] = [];
let pageViewListener: ListenerId | null = null;

export const initScrollTracking = (track: Track, store: Store) => {
  let maxScrollY = 0;
  let maxQuadrant = 0;
  let hasTrackedScroll = false;
  // use pageViewToken at time of initialization since it will change on soft navigation
  const pageViewToken = store.pageViewToken;

  listenerAr.forEach((id) => removeListener(id));
  listenerAr = [];

  if (pageViewListener) removeListener(pageViewListener);
  pageViewListener = null;

  const measureScrollDistance = () => {
    const totalHeight =
      document?.documentElement?.scrollHeight || document?.body?.scrollHeight;
    const scrollHeight = totalHeight - document?.documentElement?.clientHeight;
    const scrollTop =
      document?.documentElement?.scrollTop || document?.body?.scrollTop;
    const percScrolled = scrollTop
      ? Math.round((scrollTop / scrollHeight) * 100)
      : 0;

    if (percScrolled > maxScrollY) {
      maxScrollY = percScrolled;
      if (maxScrollY < 25) {
        maxQuadrant = 0;
      } else if (maxScrollY < 50) {
        maxQuadrant = 25;
      } else if (maxScrollY < 75) {
        maxQuadrant = 50;
      } else if (maxScrollY < 95) {
        maxQuadrant = 75;
      } else {
        maxQuadrant = 100;
      }
    }

    return maxQuadrant;
  };

  const trackMaxScrollDistance = (evt: Event) => {
    const quadrant = measureScrollDistance();
    trackQuadrant(quadrant);
  };

  const trackQuadrant = (quadrant: number) => {
    if (hasTrackedScroll) return;
    hasTrackedScroll = true;
    const event: MonorailEventSchema<WebsiteScrollEvent_1> = {
      schemaId: MonorailEventSchemaId.Scroll,
      payload: {
        pageViewToken,
        quadrant,
      },
    };
    track.dux(event, {flush: true});

    if (track.gtm) {
      track.gtm({
        event: Events.Scroll,
        scrollDepth: quadrant,
      });
    }

    // cleanup, we only want 1 event per page
    listenerAr.forEach((id) => removeListener(id));
  };

  const calcuateScroll = debounce(() => {
    const quadrant = measureScrollDistance();
    if (quadrant === 100) trackQuadrant(quadrant);
  });

  const handleSoftPageView = (evt: Event) => {
    // track the most recent scroll distance
    trackQuadrant(maxQuadrant);
    // reset scroll listeners
    initScrollTracking(track, store);
  };

  // measure scroll distance, log it immediately if we hit ~100% (95%+)
  // trackQuardrant will remove all listeners
  listenerAr.push(
    ...[
      addListener(Events.Scroll, calcuateScroll, document),
      addListener(Events.Scroll, calcuateScroll, document),
      // provides an estimate for page exit because beforeUnload is not reliable
      // in iOS users can background Safari and then close Safari resulting in no `beforeUnload` event
      addListener(Events.BeforeUnload, trackMaxScrollDistance, document.body),
      addListener(Events.VisibilityChange, trackMaxScrollDistance, document),
      addListener(Events.PageHide, trackMaxScrollDistance, window),
    ],
  );

  // On soft-page-view reset the scroll listener
  pageViewListener = onceListener(
    MonorailEventSchemaId.PageView,
    handleSoftPageView,
    document,
  );

  calcuateScroll();
};
