/* eslint-disable @typescript-eslint/naming-convention */
export const DUX_INITIALIZED_ATTRIBUTE = 'data-dux-initialized';
export const CLICK_EVENT_SELECTORS =
  'a, button, [data-click-enabled], input, select';
export const CLICK_EVENT_SELECTORS_DISABLED =
  '[data-click-disabled], [data-click-enabled="false"]';
export const GTM_DATA_SELECTORS =
  '[data-event-category], [data-ga-event], [data-ga-category]';

export const DOMAIN_LOCALE_MAPPINGS: {
  [key: string]: string | {[key: string]: string};
} = {
  // default is 'www.shopify.com'
  default: {
    au: 'en-AU',
    be: 'nl-BE',
    'be-de': 'de-BE',
    'be-en': 'en-BE',
    'be-fr': 'fr-BE',
    br: 'pt-BR',
    ca: 'en-CA',
    'ca-fr': 'fr-CA',
    cn: 'zh-CN',
    co: 'es-CO',
    cs: 'cs',
    de: 'de',
    default: 'en',
    dk: 'da',
    'dk-en': 'en-DK',
    en: 'en',
    es: 'es',
    'es-es': 'es-ES',
    fi: 'fi',
    fr: 'fr',
    hk: 'zh-HK',
    'hk-en': 'en-HK',
    id: 'en-ID',
    ie: 'en-IE',
    in: 'en-IN',
    it: 'it',
    jp: 'ja',
    ko: 'ko',
    kr: 'ko',
    mx: 'es-MX',
    my: 'en-MY',
    ng: 'en-NG',
    nl: 'nl',
    'no-en': 'en-NO',
    nz: 'en-NZ',
    ph: 'en-PH',
    'pt-pt': 'pt-PT',
    'pt-br': 'pt-BR',
    se: 'sv',
    sg: 'en-SG',
    th: 'th',
    tr: 'tr',
    tw: 'zh-TW',
    uk: 'en-GB',
    vi: 'vi',
    vn: 'vi',
    za: 'en-ZA',
    zh: 'zh-CN',
    'zh-cn': 'zh-CN',
    'zh-tw': 'zh-TW',
  },
  'www.shopify.ca': 'en-CA',
  'www.shopify.co.uk': 'en-GB',
  'www.shopify.in': 'en-IN',
  'www.shopify.com.au': 'en-AU',
  'www.shopify.co.id': 'en-ID',
  'www.shopify.com.br': 'pt-BR',
  'www.shopify.co.nz': 'en-NZ',
  'www.shopify.co.za': 'en-ZA',
  'www.shopify.com.ng': 'en',
  'www.shopify.com.ph': 'en-PH',
  'www.shopify.com.sg': 'en-SG',
  'www.shopify.hk': 'zh-HK',
  'en.shopify.hk': 'en-HK',
  'www.shopify.ie': 'en-IE',
  'en.shopify.be': 'en-BE',
  'www.shopify.my': 'en-MY',
  'en.shopify.dk': 'en-DK',
  'en.shopify.no': 'en-NO',
  'es.shopify.com': 'es',
  'www.shopify.es': 'es-ES',
  'www.shopify.com.co': 'es-CO',
  'www.shopify.com.mx': 'es-MX',
  'www.shopify.fr': 'fr',
  'fr.shopify.ca': 'fr-CA',
  'fr.shopify.be': 'fr-BE',
  'www.shopify.nl': 'nl',
  'www.shopify.be': 'nl-BE',
  'www.shopify.jp': 'ja',
  'www.shopify.tw': 'zh-TW',
  'www.shopify.de': 'de',
  'de.shopify.be': 'de-BE',
  'it.shopify.com': 'it',
  'www.shopify.dk': 'da',
  'www.shopify.co.kr': 'ko',
  'www.shopify.se': 'sv',
  'zh.shopify.com': 'zh-CN',
  'www.shopify.net.vn': 'vi',
};

export const HOME_HANDLE = 'home';

export const DEFAULT_COMPONENT_VIEWABILITY_THRESHOLD = 0.2;

export const DEFAULT_COMPONENT_VIEWABILITY_DURATION = 0;
