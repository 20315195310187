import type {WebsiteTrafficQuality_1_0} from '@shopify/monorail/lib/schemas';

import type {Track, Store} from '../types';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

export const initTrafficQualityTracking = async (
  track: Track,
  store: Store,
) => {
  const event: MonorailEventSchema<WebsiteTrafficQuality_1_0> = {
    schemaId: MonorailEventSchemaId.TrafficQuality,
    payload: {
      pageViewToken: store.pageViewToken || '',
      // eslint-disable-next-line no-eval
      evalLength: eval.toString().length,
      hypotSolve: Math.hypot(-24.42, -50.519999999999925).toString(),
      screenWidth: screen.width,
      screenHeight: screen.height,
      navigatorWebdriver: navigator.webdriver,
      // @ts-expect-error - this property only exists when the browser is being controlled through chromedriver
      seleniumDocCheck: document.$cdc_asdjflasutopfhvcZLmcfl_ !== undefined,
    },
  };

  if (navigator.mediaDevices) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    event.payload.mediaDevices = [...new Set(devices)]
      .map((device) => device.kind)
      .join(',');
  }

  track.dux(event);
};
