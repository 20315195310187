// symlinked to /config/tlds.json because rollup.config.preact errors when in parent of application root
import knownTldHosts from '../../config/tlds.json' assert {type: 'json'};

const {includedTlds, excludedTlds} = knownTldHosts;

const extractRootDomain = (url: URL): string | undefined => {
  const {hostname} = url;
  const tld = includedTlds.find((tld: string) => hostname.endsWith(tld));
  if (tld && !excludedTlds.find((tld: string) => hostname.endsWith(tld))) {
    const hostAr = hostname.split(tld);
    return `.${hostAr[0].split('.').pop()}${tld}`;
  }

  if (hostname.startsWith('www.')) {
    return `.${hostname.split('www.').pop()}`;
  }
};

export default extractRootDomain;
