import type {FormEvent} from 'react';
import type {WebsiteFormEvent_2_0} from '@shopify/monorail/lib/schemas';

import type {Store, Track} from '../types';
import {getComponentTreeInfo} from '../utils/componentTree';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';
import {addListener} from '../utils/listeners';

export const normalizeFormData = (
  event: SubmitEvent | FormEvent<HTMLFormElement>,
) => {
  const formEl: HTMLFormElement | null = (
    event?.currentTarget instanceof HTMLFormElement
      ? event?.currentTarget
      : event?.target
  ) as HTMLFormElement;
  const submitterEl: HTMLElement | null = ((event as SubmitEvent)?.submitter ||
    event?.target) as HTMLElement;
  const {action} = formEl;
  const hasError = event.defaultPrevented;
  let message = '';
  let errorEl;

  // find error message
  if (hasError) {
    errorEl = Array.from(formEl.elements).find(
      (input) => input instanceof HTMLInputElement && input.validationMessage,
    ) as HTMLInputElement;

    if (errorEl) {
      message = `${errorEl.name || errorEl.id}: ${errorEl?.validationMessage}`;
    }
  }
  const {componentTree = ''} = getComponentTreeInfo(formEl, false);
  const {componentTree: submitterComponentTree = ''} = getComponentTreeInfo(
    submitterEl,
    true,
  );

  return {
    formEl,
    submitterEl,
    errorEl,
    hasError,
    action,
    message,
    componentTree,
    submitterComponentTree,
  };
};

export const initFormTracking = (track: Track, store: Store) => {
  const handleSubmit = (evt: SubmitEvent) => {
    if (!evt) return;
    const {hasError, message, action, componentTree, submitterComponentTree} =
      normalizeFormData(evt);

    const event: MonorailEventSchema<WebsiteFormEvent_2_0> = {
      schemaId: MonorailEventSchemaId.Form,
      payload: {
        pageViewToken: store.pageViewToken || '',
        action,
        state: hasError ? 'error' : 'success',
        componentTree,
        submitterComponentTree,
        message,
      },
    };

    track.dux(event);
  };

  addListener('submit', handleSubmit, document?.body, true);
};
