export enum ElementType {
  ButtonClick = 'button_click',
  LinkClick = 'link_click',
  OutboundLinkClick = 'outbound_link_click',
  DropDownClick = 'dropdown_click',
  DropDownSelect = 'dropdown_select',
  InputSelect = 'input_select',
  ImageClick = 'image_click',
}

export interface TreeNode {
  node: Node;
  attr: string;
  attrVal: string;
}

/**
 * element.dataset converts 'data-some-attribute' to 'someAttribute'
 * To find and remove the prefix ('data-some-attribute'), we to mimic this behavior and
 * convert `data-some-attribute` to `someAttribute`
 * @param str ex: 'data-some-attribute'
 * @returns string 'someAttribute'
 */
export const camelizeDataPrefix = (str: string) =>
  // lowercase everything
  str
    .toLowerCase()
    // remove opening 'data-' prefix
    .replace(/^data-|^-/, '')
    // convert -a to A
    .replace(/-./g, (letter) => (letter[1] || '').toUpperCase())
    // replace any extraneous '-'
    .replace(/-/g, '');
// make sure first character is lowercase

/**
  Discussed normalization techniques with analytics team.
  Below we normalize a lot of various text types to human readable kebab-casing
  Please review the snapshots in the componenTree test to see expected output
 * @param str string
 * @returns normalized string
 */
export const normalizeName = (str: string) => {
  // normalize escaped url characters
  let result = str;
  try {
    result = decodeURIComponent(str);
  } catch (err) {
    // will fail if % is not followed by 2 hex digits
  }
  // normalize casing to camel case first
  result = result.replace(
    /[A-Z]{2,}/g,
    (letter) => `${letter.charAt(0)}${letter.substring(1).toLowerCase()}`,
  );
  result = result.charAt(0).toLowerCase() + result.substring(1);
  // kebab case other delimiters
  result = result.replace(/[\s_:./,\\\n|]/g, '-');
  // kebab case uppers
  result = result.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  // dedupe dashes
  result = result.replace(/-{2,}/g, '-');
  // replace non-latin characters with '?'
  result = result.replace(/[^a-z0-9-]/g, '?');
  // trim leading and trailing dashes
  result = result.replace(/(^-)|(-$)/g, '');
  // Should be less than 50 chars for GoogleAnalytics
  result = result.substring(0, 50);

  return result;
};

/**
 * Determine if element (or any element parent) is fixed/sticky
 * @param ele
 * @returns
 */
export const isFixedPosition = (ele: Element | null) => {
  let node = ele;
  while (node && node.nodeName.toLowerCase() !== 'body') {
    if (
      getComputedStyle(node)
        .getPropertyValue('position')
        .match(/fixed|sticky/i)
    ) {
      return true;
    }
    node = node.parentNode as Element;
  }
  return false;
};

/**
 * Dynamically get a button name (label) from a number of possible options
 * @param ele
 * @returns
 */
export const getElementName = (ele: HTMLElement) => {
  const label =
    ele.dataset.sectionName ||
    ele.dataset.componentName ||
    ele.getAttribute('name') ||
    ele.getAttribute('id') ||
    ele.getAttribute('aria-label') ||
    ele.innerText ||
    ele.textContent ||
    `unhandled-${ele.tagName}`;
  return normalizeName(label);
};

export const isShopifyLink = (url: string) => {
  try {
    return new URL(url, document.baseURI).origin.match(
      /shopify.|myshopify.io|spin.dev/,
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('isShopifyLink', err);
    return false;
  }
};

export const getElementType = (
  ele: HTMLElement,
): ElementType | `${string}_click` => {
  const tagName = ele.tagName.toLowerCase();
  switch (tagName) {
    case 'button':
      return ElementType.ButtonClick;
    case 'a':
      return isShopifyLink(ele.getAttribute('href') || '')
        ? ElementType.LinkClick
        : ElementType.OutboundLinkClick;
    case 'select':
      return ElementType.DropDownClick;
    case 'option':
      return ElementType.DropDownSelect;
    case 'input':
      return (ele as HTMLInputElement).type === 'button'
        ? ElementType.ButtonClick
        : ElementType.InputSelect;
    case 'img':
      return ElementType.ImageClick;
    default:
      return `${tagName}_click`;
  }
};

/**
 * Extract only the `extraMetadataAttribute` data fields and remove the `extraMetadataAttribute` so the resulting
 * StringMap is consise. (ex: extractExtraMetadata({componentExtraSomeField: 'test'}) => {someField: 'test'} )
 * @param dataset
 * @param camelizedPrefix `someDataPropPrefix`
 * @returns dataset with `extraMetadataAttribute` detail removed in order to keep the dataset concise
 */
export const extractNodeMetadata = (
  dataset: DOMStringMap,
  camelizedPrefix: string,
) => {
  const metadata: {[key: string]: string} = {};
  // camelcase with no "data" prop...
  for (const attr in dataset) {
    if (attr.startsWith(camelizedPrefix)) {
      const shortAttr = attr.substring(camelizedPrefix.length);
      if (shortAttr) {
        const normalizedAttr =
          shortAttr.charAt(0).toLowerCase() + shortAttr.substring(1) || '';
        if (normalizedAttr) {
          metadata[normalizedAttr] = dataset[attr] || '';
        }
      }
    }
  }
  return metadata;
};

export const EXTRA_METADATA_ATTRIBUTE_PREFIX = 'data-component-extra-';

/**
 * Walks DOM tree from target component to extract all `data-attribute-prefix` data into a shallow JSON object
 * @param component target html component to work down ancestor node path extracting all `data-attribute-prefix-` data from
 * @param dataAttributePrefix example: `data-component-extra-`, all data fields prefixed with `data-component-extra` are extracted
 * @returns JSON map camel-case with dataAttributePrefix removed. Ex: data-component-extra-some-field: 'test' returns {'someField': 'test'}
 */
export const extractComponentTreeMetadata = (
  component: HTMLElement,
  dataAttributePrefix: string = EXTRA_METADATA_ATTRIBUTE_PREFIX,
): {[key: string]: string} => {
  const ancestors = document.evaluate(
    `ancestor::*[@*[starts-with(name(), ${dataAttributePrefix})]]`,
    component,
    null,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null,
  );

  const camelizedPrefix = camelizeDataPrefix(dataAttributePrefix);

  const metadata: {[key: string]: string} = {};
  let node: Node | null;
  while ((node = ancestors.iterateNext())) {
    // The element.dataset browser API converts 'data-*' props to a camelCase datamap
    Object.assign(
      metadata,
      extractNodeMetadata((node as HTMLElement).dataset, camelizedPrefix),
    );
  }
  Object.assign(
    metadata,
    extractNodeMetadata(component.dataset, camelizedPrefix),
  );
  return metadata;
};

export const getComponentTreeByAttr = (
  component: HTMLElement,
  attr: string,
): TreeNode[] => {
  const ancestors = document.evaluate(
    `ancestor::*[@${attr}[1]]`,
    component,
    null,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null,
  );
  // If we find multiple ancestors, then we need to reverse() them to get the closest one
  let node: any;
  const sections = [];
  while ((node = ancestors.iterateNext())) {
    const attrVal = (node as HTMLElement).getAttribute(attr);
    if (attrVal) {
      sections.push({
        node,
        attr,
        attrVal,
      });
    }
  }
  return sections;
};

export const getComponentTree = (
  component: HTMLElement,
  attrs = ['data-section-name', 'data-component-name', 'id'],
): TreeNode[] | undefined => {
  let treeNodes;
  let attr;
  for (attr of attrs) {
    treeNodes = getComponentTreeByAttr(component, attr);
    if (treeNodes.length > 0) break;
  }
  return treeNodes;
};

export const getParentSectionName = (
  component: HTMLElement,
  attrs?: string[],
) => {
  const treeNodes = getComponentTree(component, attrs) || [];
  const sectionNode = treeNodes.pop();
  const {attrVal = ''} = sectionNode || {};
  const index = getElementIndex(sectionNode);
  return {
    name: normalizeName(attrVal || `unhandled-parent-section`),
    index,
  };
};

export const getElementIndex = (treeNode: TreeNode | undefined): number => {
  if (!treeNode) return 0;
  const {attr, attrVal, node: section} = treeNode;
  const nodes = document.querySelectorAll(`[${attr}="${attrVal}"]`);
  if (nodes.length <= 1) return 0;
  return Array.from(nodes).findIndex((node) => node.isEqualNode(section));
};

export const getComponentTreeInfo = (
  component: HTMLElement,
  includeTarget = true,
  attrs?: string[],
): {
  elementName?: string;
  elementType?: string;
  sectionName?: string;
  sectionIndex?: number;
  componentTree?: string;
  extraMetadata?: {[key: string]: string};
} => {
  if (!component) return {};

  const elementName = getElementName(component);
  const elementType = getElementType(component);
  const {name: sectionName, index: sectionIndex} =
    getParentSectionName(component);
  const componentTreeNodes = getComponentTree(component, attrs) || [];
  const componentTreeAr = [];
  const extraMetadata = extractComponentTreeMetadata(component);

  componentTreeNodes.forEach(({attrVal}) => {
    componentTreeAr.push(normalizeName(attrVal));
  });
  componentTreeAr.push(elementName);

  const componentTreeStr = componentTreeAr
    .join('|')
    .concat(includeTarget ? `:${normalizeName(component.tagName)}` : ``);

  return {
    elementName,
    elementType,
    sectionName,
    sectionIndex,
    componentTree: componentTreeStr,
    extraMetadata,
  };
};
