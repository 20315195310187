/* eslint-disable @typescript-eslint/naming-convention */
import type {ConsentAllStatus} from '../enums';
import type {
  ConfigMetadata,
  Mode,
  DuxEvent,
  ViewabilityHandler,
} from '../types';
import type {MonorailEventSchemaId} from '../schema-types';

// eslint-disable-next-line @shopify/typescript/prefer-singular-enums
export enum Events {
  BeforeUnload = 'beforeunload',
  Error = 'error',
  Load = 'load',
  PageHide = 'pagehide',
  PageShow = 'pageshow',
  Scroll = 'scroll',
  VisibilityChange = 'visibilitychange',
}

export enum GtmEventType {
  ComponentViewability = 'component_viewability',
}

export interface GtmClickEventData {
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
  eventValue?: string;
  dimension1?: string;
  /* @deprecated Removing in favor of `event` */
  eventName?: string;
  event?: string;
}

export interface GtmComponentViewabilityData {
  event: GtmEventType.ComponentViewability;
  eventType?: string;
  eventLocation?: string;
  extraMetadata?: string;
}

export interface GtmScrollEventData {
  event: Events.Scroll;
  scrollDepth: number;
}

export interface GtmContext {
  emailAddress?: string;
  shopName?: string;
}

export type GtmEvent = GtmClickEventData & {
  project: string;
  user_token: string;
  event: string;
  event_non_interaction: string;
  event_context: GtmContext;
};

export type PageViewCallback = (store: Store) => {
  [key: string]: string | number | boolean | undefined;
};

export interface DisableLogger {
  page?: boolean;
  click?: boolean;
  error?: boolean;
  visibility?: boolean;
  webVitals?: boolean;
  trafficQuality?: boolean;
  componentViewability?: boolean;
  scroll?: boolean;
  form?: boolean;
}

export interface DidomiConfig {
  isEnabled: boolean;
  accountId?: string | undefined;
  noticeId?: string | undefined;
  onReady?: (didomi: any) => void;
  // see https://developers.didomi.io/cmp/web-sdk/consent-notice/notice/behavior for didomiConfig behaviour
  didomiConfig?: any;
}
export interface DuxConfig {
  mode: Mode;
  service: string;
  metadata?: ConfigMetadata;
  enableActiveConsent?: boolean;
  enableGtm?: boolean;
  enableGtmLoader?: boolean;
  onPageView?: PageViewCallback;
  emitTrekkiePageViewEvent?: boolean;
  eventHandlerEndpoint?: string;
  countryCode?: string;
  regionCode?: string;
  canonicalUrl?: string;
  didomi?: DidomiConfig;
  disableLogger?: DisableLogger;
  experimentVariationId?: string;
  shopId?: string;
  sessionId?: string;
  identityUuid?: string;
  userId?: number;
  shopifyEmployee?: boolean;
  shopifyEmployeeId?: string;
  tabToken?: string;
}

export type UpdatePageView = (store: Partial<Store>) => void;
export type UpdateStore = UpdatePageView;

export interface ComputedSiteData {
  isReady: boolean;
  multiTrackToken: string;
  pageViewToken: string;
  sessionToken: string;
  complianceZone: string;
  url: string;
  pageLanguageCode?: string;
  lastShopDomain?: string;
  isNewUser?: boolean;
  privacyState?: PrivacySettings;
  didomiToken?: string;
  didomiStore?: DidomiStore;
}

export interface Store extends ComputedSiteData {
  debug?: boolean;
  emitTrekkiePageViewEvent?: boolean;
  enableActiveConsent?: boolean;
  enableGtm?: boolean;
  eventHandlerEndpoint?: string;
  metadata?: ConfigMetadata;
  mode?: Mode;
  service: string;
  countryCode?: string;
  regionCode?: string;
  canonicalUrl?: string;
  experimentVariationId?: string;
  shopId?: string;
  sessionId?: string;
  identityUuid?: string;
  userId?: number;
  shopifyEmployee?: boolean;
  shopifyEmployeeId?: string;
  tabToken?: string;
}

export interface DuxTrackerOptions {
  preserveCase?: string[];
  flush?: boolean;
  clientMessageId?: string;
}

export interface Track {
  dux: (event: DuxEvent<any>, options?: DuxTrackerOptions) => void;
  gtm?: (
    event: GtmClickEventData | GtmScrollEventData | GtmComponentViewabilityData,
    context?: GtmContext,
  ) => void;
}

export type UpdateTrackersHook = (trackers: Track) => void;
export type UpdateStoreHook = (
  store: Partial<Store>,
  globStore?: Partial<Store>,
) => void;
export type EmitPageViewHook = (details?: Partial<Store>) => void;

export interface DidomiStore {
  user_id: string;
  created: string;
  updated: string;
  version: number | null;
  purposes?: {
    enabled: string[];
    disabled: string[];
  };
  vendors?: {
    enabled: string[];
    disabled: string[];
  };
  vendors_li?: {
    enabled: string[];
  };
}

export interface PrivacySettings {
  consentedAll: ConsentAllStatus;
  countryCode: string;
  regionCode?: string;
  complianceZone: string;
  date: string;
  rootDomain: string;
  url: string;
  apiVersion: string;
}

declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
    utag: any;
    cmp: any;
    didomiConfig: any;
    didomiOnReady: any;
    didomiEventListeners: any;
  }

  interface HTMLElementEventMap {
    [MonorailEventSchemaId.PageView]: CustomEvent;
  }

  interface String {
    toWellFormed(): string;
  }
}

export interface ViewabilityObservation {
  id: string;
  dom: HTMLElement;
  handler?: ViewabilityHandler;
  observer: IntersectionObserver;
  threshold: number;
  duration: number;
  timer?: ReturnType<typeof setTimeout>;
  clientMessageId: string;
  extraMetadata: {[key: string]: string};
}

export interface ViewabilityOptions {
  threshold?: number;
  duration?: number;
  extraMetadata?: {[key: string]: string};
}
