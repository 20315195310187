const getTrekkie = () =>
  new Promise((resolve) => {
    const pollTrekkie = () => {
      const trekkie = window.analytics?.trekkie;

      if (trekkie) {
        resolve(trekkie);
      } else {
        setTimeout(pollTrekkie, 100);
      }
    };
    pollTrekkie();
  });

export const getMicroSessionId = () =>
  getTrekkie().then(
    (trekkie: any) => trekkie.defaultAttributes?.microSessionId || '',
  );

export const getAppName = () =>
  getTrekkie().then(
    (trekkie: any) =>
      trekkie.defaultAttributes?.application ||
      trekkie.defaultAttributes?.appName ||
      '',
  );
