import type {ReportCallback, Metric} from 'web-vitals';
import {onCLS, onFID, onLCP, onTTFB, onFCP} from 'web-vitals';
import type {WebsiteWebVitalsEvent_2_0} from '@shopify/monorail/lib/schemas';

import {raceListeners} from '../utils/listeners';
import {getLastFpsDetected} from '../utils/webgl';
import type {Track, Store} from '../types';
import {Events} from '../types';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

export const initWebVitalsTracking = (track: Track, store: Store) => {
  // use pageViewToken at time of initialization since it will change on soft navigation
  const pageViewToken = store.pageViewToken;
  const webVitals: Metric[] = [];
  const queueWebVital: ReportCallback = (webVital) => {
    webVitals.push(webVital);
  };
  onCLS(queueWebVital);
  onFID(queueWebVital);
  onLCP(queueWebVital);
  onTTFB(queueWebVital);
  onFCP(queueWebVital);

  const sendWebVitals = () => {
    const payload: {[key: string]: number} = {};
    if (webVitals.length) {
      webVitals.forEach(({name, value, entries}: Metric) => {
        const dimension = name.toLocaleLowerCase();
        payload[dimension] = Math.round(value);

        if (name === 'CLS') {
          // measure the number of cummulative layout shifts?
          payload[`${dimension}Entries`] = entries.length;
        }
      });

      const fps = Math.round(getLastFpsDetected() || 0);

      const event: MonorailEventSchema<WebsiteWebVitalsEvent_2_0> = {
        schemaId: MonorailEventSchemaId.WebVitals,
        payload: {
          pageViewToken,
          ...(fps ? {framesPerSecond: fps} : {}),
          ...(payload.ttfb ? {timeToFirstByte: payload.ttfb} : {}),
          ...(payload.fcp ? {firstContentfulPaint: payload.fcp} : {}),
          ...(payload.fid ? {firstInputDelay: payload.fid} : {}),
          ...(payload.lcp ? {largestContentfulPaint: payload.lcp} : {}),
          ...(payload.cls ? {cumulativeLayoutShift: payload.cls} : {}),
          ...(payload.clsEntries
            ? {cumulativeLayoutShiftEntries: payload.clsEntries}
            : {}),
        },
      };

      track.dux(event, {flush: true});
    }
  };

  raceListeners([
    [Events.BeforeUnload, sendWebVitals, document.body, true],
    [Events.VisibilityChange, sendWebVitals, document, true],
    [Events.PageHide, sendWebVitals, window, true],
    [MonorailEventSchemaId.PageView, sendWebVitals, document, true],
  ]);
};
