/* eslint-disable line-comment-position */
import {ComplianceZone} from '../../enums';

export const GDPR_COUNTRY_CODE = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IS', // Iceland
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SI', // Slovenia
  'SK', // Slovakia
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // United Kingdom
];

export const getComplianceZone = (
  countryCode = '',
  regionCode = '',
): ComplianceZone => {
  if (countryCode && GDPR_COUNTRY_CODE.includes(countryCode.toUpperCase())) {
    return ComplianceZone.Gdpr;
  }
  if (countryCode.toUpperCase() === 'US' && regionCode.toUpperCase() === 'CA') {
    return ComplianceZone.Ccpa;
  }
  return ComplianceZone.None;
};
